import { FC } from "react";
import { DataSourceResponseDTO } from "../../../clients/collector.client";
import { CFCard, CFContainer, CFList, CFNestedCard } from "../../../components/layout/CFContainer";
import { groupBy } from "../../../utils/functional/reduce.utils";
import { ListItem } from "@material-ui/core";
import { AttributeResponseDTO, AttributeTypeDTODisplayStateEnum, CollectorConfigResponseDTO } from "../../../open-api";
import { useServices } from "../../../services/context.service";
import { useApiCall, useDataClientPromise } from "../../../hooks/client.hooks";
import { CollectorHierarchy } from "./CollectorHierarchy";
import { AttributeFilterSection } from "../organizationFilter/components/AttributeFilterSection";

interface CollectorAttributesProps {
  collector: DataSourceResponseDTO;
  collectorConfig: CollectorConfigResponseDTO | null;
  onUpdate: () => void;
}

export const CollectorAttributes = ({ collector, collectorConfig, onUpdate }: CollectorAttributesProps) => {
  const { attributes } = useServices();
  const [allAttributes, refreshAllAttributes, isLoading] = useDataClientPromise(attributes.getForCollector, [
    collector.id,
  ]);
  const [saveAttributes] = useApiCall(attributes.updateDisplayState);

  const possibleAttributes = allAttributes;
  const groupedAttributes = groupBy(possibleAttributes ?? [], (it) => it.type.type);
  const defaultAttributes = Object.keys(groupedAttributes)
    .map((it) => groupedAttributes[it])
    .filter((it) => it.length === 1)
    .map((it) => it[0]);
  const customAttributes = Object.keys(groupedAttributes)
    .map((it) => groupedAttributes[it])
    .filter((it) => it.length > 1);

  const handleSave = (changedMap: Record<string, AttributeTypeDTODisplayStateEnum>) =>
    saveAttributes({
      updateAttributeDisplayStateRequestDTO: { newStates: changedMap, collectorId: collector.id },
    }).then(refreshAllAttributes);

  return (
    <>
      {defaultAttributes.length === 0 ? (
        <CFCard header={"Attributes"} isLoading={isLoading}>
          There are no attributes available yet for this collector, please wait a short while until the cloud data is
          processed.
        </CFCard>
      ) : (
        <CFCard header={"Default attributes"} isLoading={isLoading}>
          The C-facts collector and processor process and store the following attributes for every line item we retrieve
          from the API.
          <CFContainer>
            <CFNestedCard xs={4} header={"Currently processed attributes"}>
              <CFList>
                {defaultAttributes.map((it) => (
                  <DefaultAttribute attribute={it} />
                ))}
              </CFList>
            </CFNestedCard>
          </CFContainer>
        </CFCard>
      )}
      {customAttributes.map((it) => {
        const title = it[0].type.attributeDisplayValue;
        const SubtitleComponent = () => (
          <>
            {`For the `}
            <strong>{`${title}`}</strong>
            {` attribute, please select which keys you want to use in your dashboards and reporting.`}
          </>
        );

        return (
          <AttributeFilterSection
            attributes={it}
            onSave={handleSave}
            title={`Attribute: ${title}`}
            SubtitleComponent={SubtitleComponent}
          />
        );
      })}
      <CollectorHierarchy
        attributes={possibleAttributes?.filter((it) => it.type.displayState === "INCLUDE")}
        collector={collector}
        collectorConfig={collectorConfig}
        onUpdate={onUpdate}
      />
    </>
  );
};

export const DefaultAttribute: FC<{ attribute: AttributeResponseDTO }> = ({ attribute }) => (
  <ListItem divider>{attribute.type.attributeDisplayValue}</ListItem>
);
